'use client'
import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { signOut, useSession } from 'next-auth/react'
import useLocalStorage from '@/lib/useLocalStorage'
import Icon from '@/lib/Icon'

export default function TopNavigation() {
  const { data: session } = useSession()
  const { value: searchId } = useLocalStorage('search-id')

  const [showMenu, setShowMenu] = React.useState(false)

  return (
    <nav className="bg-brand w-full flex justify-center items-stretch h-[58px]">
      {showMenu && <div className="absolute inset-0 top-[58px] bg-overlay z-20" />}
      <div className="relative flex flex-[1_0_0%] justify-between items-center max-w-screen-lg px-9 lg:px-0">
        <Link href="/" className="no-underline">
          <Image
            src="/logo.svg"
            alt="Orang Energy logo with wordmark"
            height={24}
            width={190}
            priority
          />
        </Link>
        <Menu
          show={showMenu}
          setShow={setShowMenu}
          button={<Icon icon="menu" width={25} height={25} />}
        >
          <div
            className="absolute top-[58px] right-0 z-20 bg-brand text-white pt-2 pr-6 pb-3 pl-4 focus:outline-none flex flex-col items-start w-max rounded-bl-lg lg:rounded-br-lg divide-y divide-divider [&>*]:p-2 [&>*]:w-full"
          >
            <Link href="/" className="no-underline hover:text-yellow">Homepage</Link>
            <Link href="/calculator" className="no-underline hover:text-yellow">Energy savings calculator</Link>
            <Link href={`/installers${searchId != null ? `?searchId=${searchId}` : ''}`} className="no-underline hover:text-yellow">Installers near you</Link>
            <Link href="https://learn.orang.energy/about" target="_blank" className="no-underline hover:text-yellow">About us</Link>
            {session && (
              <button
                className="hover:text-yellow text-left"
                role="menuitem"
                tabIndex={-1}
                onClick={() => signOut({ callbackUrl: '/' })}
              >
                Log out
              </button>
            )}
          </div>
        </Menu>
      </div>
    </nav>
  )
}

function Menu(
  props: {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    button: React.ReactNode
    children: React.ReactNode
  },
): React.ReactNode {
  return (
    <div>
      <button className="block p-1" onClick={() => props.setShow(isShown => !isShown)}>
        {props.button}
      </button>
      {props.show && (
        <MenuContent close={() => props.setShow(false)}>
          {props.children}
        </MenuContent>
      )}
    </div>
  )
}

function MenuContent(
  props: {
    children: React.ReactNode
    close: () => void
  },
): React.ReactNode {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    document.scrollingElement?.scroll({ top: 0 })

    const listener = () => {
      if (ref.current) {
        props.close()
      }
    }
    document.addEventListener('click', listener)

    const { overflow } = document.body.style
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = overflow
      document.removeEventListener('click', listener)
    }
  })

  return (
    <div
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
      tabIndex={-1}
      ref={ref}
    >
      {props.children}
    </div>
  )
}
